<template>
  <b-row>

    <b-col
      cols="12"
      md="6"
      class=" mb-1"
    >

      <b-table-simple
        class="table-details"
        responsive
        no-border-collapse
      >
        <b-tbody>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_first_name') }}
            </b-td>
            <b-td class="actions">

              {{ itemData.first_name }}

            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_last_name') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.last_name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_company_name') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.firm_name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_nip') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.nip }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="50%">
              {{ $t('label_client_bank_account_number') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.bank_account_number }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_swift_number') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.swift_number }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_pesel_number') }}
            </b-td>
            <b-td class="actions">
              {{ itemData.pesel }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_email_address') }}
            </b-td>
            <b-td class="actions">

              {{ itemData.email }}

            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_private_email') }}
            </b-td>
            <b-td class="actions">

              {{ itemData.email_private }}

            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_phone') }}
            </b-td>
            <b-td class="actions">

              {{ itemData.phone }}

            </b-td>
          </b-tr>
          <b-tr>
            <b-td width="40%">
              {{ $t('label_private_phone') }}
            </b-td>
            <b-td class="actions">

              {{ itemData.phone_private }}

            </b-td>
          </b-tr>

        </b-tbody>
      </b-table-simple>
    </b-col>
    <b-col
      cols="12"
      md="6"
      class=" mb-1"
    >
      <b-table-simple
        class="table-details"
        responsive
        no-border-collapse
      >
        <b-tbody>
          <b-tr>
            <b-td>{{ $t('label_status') }}</b-td>
            <b-td>
              {{ $t(itemData.status? itemData.status.translation_index : '') }}

            </b-td>
          </b-tr>

          <b-tr>
            <b-td>{{ $t('label_origin') }}</b-td>
            <b-td>
              {{ $t(itemData.referral_user_id ? $t('label_marketing') : '') }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('label_address_from_agreement') }}</b-td>
            <b-td>
              {{ itemData.address_agreement }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('label_correspondence_address') }}</b-td>
            <b-td>
              {{ itemData.address_correspondence }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('label_deal_type') }}</b-td>
            <b-td>
              {{ itemData.deal_type? itemData.deal_type.name : '' }}
            </b-td>
          </b-tr>
          <!--          <b-tr v-if="$can('view', 'deal.tab_details') && itemData.deals">-->
          <!--            <b-td>{{ $t('label_related_deal') }}</b-td>-->
          <!--            <b-td>-->

          <!--              <template v-for="relDeal,ind in itemData.deals">-->
          <!--                <router-link-->

          <!--                  :key="'deal_'+relDeal.id"-->
          <!--                  target="_blank"-->
          <!--                  :to="{ name: 'deal-details', params: { id: relDeal.id } }"-->
          <!--                >-->
          <!--                  {{ relDeal.number }}-->
          <!--                </router-link>-->
          <!--                {{ ((ind+1 == itemData.deals.length)? '' : ' | ') }}-->
          <!--              </template>-->


          <!--            </b-td>-->
          <!--          </b-tr>-->

          <b-tr>
            <b-td>{{ $t('label_consultant_leading_case') }}</b-td>
            <b-td>
              {{ itemData.consultant? itemData.consultant.name : '' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('label_recommended') }}</b-td>
            <b-td>

              {{ itemData.agent? itemData.agent.name : '' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>{{ $t('label_related_users') }}</b-td>
            <b-td>

              <template v-for="user,ind in itemData.users">
                <router-link
                  :key="user.id"
                  :to="resolveRouteToRedirectUser(user, true)"
                >
                  {{ user.name }}
                </router-link>
                {{ ((ind+1 == itemData.users.length)? '' : ' | ') }}
              </template>


            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>

    <b-col
      v-if="$can('view', 'deal.tab_details') && itemData.deals"
      sm="12"
    >
      <b-alert
        show
        variant="primary"
        class="p-0"
      >
        <div class="alert-heading">
          {{ $t('label_conected_cases') }}
        </div>
      </b-alert>
      <b-row>
        <b-col
          v-for="(relDeal) in itemData.deals"
          sm="12"
          md="4"
          lg="3"
        >
          <router-link
            :key="'deal_'+relDeal.id"
            target="_blank"
            class="d-flex mb-25"
            :to="{ name: 'deal-details', params: { id: relDeal.id } }"
          >
            <div style="width: 90px">{{ relDeal.number }}</div> <b-badge variant="primary">{{ getStatusAlias(relDeal.status_id) }}</b-badge>
          </router-link>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
 BRow, BCol, BTableSimple, BTbody, BTr, BTd, BButton, BBreadcrumbItem, BAlert, BBadge,
} from 'bootstrap-vue'
import {resolveRouteToRedirectUser} from "../../pagesHelper"

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BButton,
    BBreadcrumbItem,
    BBadge,
  },
  props: ['itemData'],
  data() {
      return {
        resolveRouteToRedirectUser,
        agreement_statuses: [],
      }
  },
  mounted() {
    this.async('get', '/select_options', {params:{options:['agreement_statuses']}}, function(res){
      this.agreement_statuses = res.data.agreement_statuses;
    });
  },
  methods: {
    getStatusAlias(status_id) {
      let alias = this.agreement_statuses.findWhere('id', String(status_id)) ? this.agreement_statuses.findWhere('id', String(status_id)).translation_index : '--';
      return this.$i18n.t(alias)
    },
  }
}
</script>
