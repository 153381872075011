<template>

  <b-modal
    :id="PREFIX + '-modal'"
    hide-footer
    @hide="resetForm()"
    @shown="initData()"
  >
    <template #modal-title>
      {{ action == 'editing'?$t('label_edit_note'):$t('label_add_note') }}
    </template>
    <template #default="{ hide }">


      <validation-observer
        #default="{handleSubmit, reset, invalid}"
        :ref="PREFIX + '_FORM'"
        tag="form"
        class="p-1"
      >

        <input
          id="n_id"
          v-model="itemData.id"
          type="hidden"
          name="id"
        >
        <input
          v-model="itemData.contact_id"
          type="hidden"
          name="contact_id"
        >
        <validation-provider
          #default="validationProps"
          :name="$t('label_subject')"
          rules="required"
          slim
          mode="aggressive"
        >
          <b-form-group
            :label="$t('label_subject')"
            label-for="n-title"
          >
            <b-form-input
              id="n-title"
              v-model="itemData.title"
              :state="getValidationState(validationProps)"
              :placeholder="$t('label_subject')"
            />

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider

          #default="validationProps"
          :name="$t('label_description')"
          rules="required"
          slim
        >
          <b-form-group
            :label="$t('label_description')"
            label-for="n-description"
          >
            <quill-editor
              id="n-description"
              ref="editor"
              v-model="itemData.description"
              class="product"
              placeholder=""
              :options="editorOption"
              :state="getValidationState(validationProps)"
            >
              <div
                id="toolbar"
                slot="toolbar"
              >
                <!-- Add a bold button -->
                <span class="ql-formats">
                  <button class="ql-clean" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                </span>
                <span class="ql-formats">
                  <select class="ql-align" />
                  <select class="ql-color" />
                </span>
                <span class="ql-formats">
                  <select class="ql-size" />
                  <select class="ql-font" />
                </span>
              </div>
            </quill-editor>

            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider

          #default="validationProps"
          :name="$t('label_date')"
          rules="required"
          class="mr-1 flex-grow-1"
          slim
          bails
          detect-input
          :immediate="false"
        >
          <b-form-group
            :label="$t('label_date')"
          >

            <custom-date-picker
              :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
              :locale="$store.state.appConfig.locale"
              :placeholder="$t('label_DD-MM-YYYY')"

              :value="itemData.deadline_at"
              input-format="DD/MM/YYYY"
              format="DD/MM/YYYY"
              @input="itemData.deadline_at = $event"
            >
              <template #clear-btn="{ vm }">
                <feather-icon
                  icon="XIcon"
                  size="14"
                />
              </template>
              <div slot="label">
                <feather-icon
                  title="Clear"
                  data-toggle
                  icon="CalendarIcon"
                  size="18"
                />
              </div>
            </custom-date-picker>


            <b-form-invalid-feedback :state="getValidationState(validationProps)">
              {{ validationProps.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


        <label
          for="share_to_role"
          class="w-100"
        >
          <b-form-checkbox
            v-model="selectedAll"
            inline
            name="event-filter-all"
            class="mb-1"
            :class="`custom-control-primary`"
            @change="selectAllRoles"
          >
            {{ $t('label_share_to_role') }}
          </b-form-checkbox>
        </label>
        <b-form-checkbox-group
          id="share_to_role"
          v-model="itemData.share_to_role"
          name="share_to_role"
          stacked
          :label="$t('label_share_to_role')"
        >
          <b-form-checkbox
            v-for="item in roleItems"
            :key="item.role"
            :disabled="item.disabled"
            name="event-filter"
            :value="getRole(item.role)"
            class="mb-1 col-sm-6"
            :class="`custom-control-primary`"
          >
            {{ $t('label_' + item.role) }}
          </b-form-checkbox>
        </b-form-checkbox-group>

        <div class="d-flex justify-content-end mt-2">
          <button
            type="reset"
            class=" btn btn-default"
            @click.prevent="$bvModal.hide(PREFIX + '-modal')"
          >
            {{ $t('label_close') }}
          </button>

          <button
            type="submit"
            class="btn btn-primary waves-effect waves-light"
            @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
          >{{ (action == 'editing')?$t('label_save'):$t('label_submit') }}</button>
        </div>

      </validation-observer>

    </template>

  </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'
    import { quillEditor } from 'vue-quill-editor'

    import {
      BFormTextarea,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BInputGroupPrepend,
      BFormCheckboxGroup,
    } from 'bootstrap-vue'

    import { CONTACT_PREFIX as MODULE_PREFIX,  NOTE_PREFIX as PREFIX} from "../moduleHelper"

    export default {
        components: {
          quillEditor,
            BForm,
BFormGroup,
BFormInput,
BFormInvalidFeedback,
BFormTextarea,
BInputGroup,
BInputGroupAppend,
BFormCheckbox,
BInputGroupPrepend,
BFormCheckboxGroup,

            ValidationProvider,
            ValidationObserver,
            // vSelect
        },
        props:['editedItem'],
        data() {
            return {
              editorOption: {
                modules: {
                  toolbar: '#toolbar',
                },
              },
                userData: JSON.parse(localStorage.getItem('userData')),
                MODULE_PREFIX,
                PREFIX,

                action: 'adding',

                blankItemData: {
                    id:0,
                    title: '',
                    description: '',
                    deadline_at: this.$moment().format('DD-MM-YYYY'),
                    contact_id: 0,
                    share_to_role: [],
                },

                itemData: {},
                roleItems: [],
              selectedAll: false,
            }
        },
        computed: {
          defaultRoles() {
            return this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
          },
          disabledRoles() {
            return this.roleItems.map(role => (!role.disabled ? this.getRole(role.role, true) : '')).filter(Boolean)
          },
        },
      watch: {
          'itemData.share_to_role': {
            deep: true,
            handler(newValue) {
              if (this.disabledRoles.length === newValue?.length) this.selectedAll = true
              else this.selectedAll = false
            },
          },
      },

        async mounted (){
          await this.getPageRoles()
          this.blankItemData.contact_id = this.$router.currentRoute.params.id;
        },
        methods: {
          selectAllRoles(value) {
            if (value) this.itemData.share_to_role = this.disabledRoles
            else this.itemData.share_to_role = []
          },
            initData(){
                let editedItem = this.editedItem;
                if(editedItem){
                    this.action = 'editing';
                    editedItem = { ...editedItem};
                    editedItem.deadline_at = formatDate(editedItem.deadline_at, 'DD/MM/YYYY', 'unix');
                    editedItem.share_to_role = typeof editedItem.share_to_role === 'string' ? (editedItem.share_to_role?.split(',').filter(Boolean) ?? []) : editedItem.share_to_role
                    this.itemData = editedItem;
                } else {
                    this.action = 'adding';
                    this.itemData =  { ...this.blankItemData}
                    delete this.itemData.id
                    this.itemData.share_to_role = this.roleItems.map(role => (role.default ? this.getRole(role.role, true) : '')).filter(Boolean)
                }
            },
            async getPageRoles(){
              this.roleItems = this.getSystemRoles(this.userData?.role)
            },
            resetForm() {
                this.itemData = { ...this.blankItemData};
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                if(this.action == 'adding') {
                    this.async('post', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s', this.itemData, function(resp){
                        this.$emit('item-added', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                } else {
                    this.async('put', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/'+this.itemData.id, this.itemData, function(resp){ // this.itemData.contact_id
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
.ql-container {
  min-height: 200px;
}
</style>
