<template>
  <b-row>

    <b-col
      cols="12"
      md="12"
      class=" mb-1"
    >

      <div class="pb-2 mt-1 table-top-toolbar">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="tableData.perPage"
              :searchable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tableData.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>

          </b-col>


        </b-row>

      </div>

      <div class="table-container-wrap">
        <b-table
          :ref="PREFIX+'_TABLE'"
          class="position-relative transited-table"
          :items="tableItems"
          responsive
          no-border-collapse
          :busy="isBusy"
          :fields="columnsDefs"
          primary-key="id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
        >

          <template #head()="data">
            <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
          </template>

          <template #cell(description)="data">
            <partial-text
              :is-html="true"
              :text="data.item.description"
            />
          </template>

          <template #cell(share_to_role)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              <feather-icon
                size="14"
                :icon="row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              />
            </b-button>
          </template>

          <template #row-details="row">
            <b-form-checkbox-group
              id="share_to_role"
              v-model="row.item.share_to_role"
              name="share_to_role"
              stacked
              :label="$t('label_share_to_role')"
              @input="updateShareToRoles(row.item.share_to_role, row.item)"
            >
              <b-form-checkbox
                v-for="item in roleItems"
                :key="item.role+row.item.id"
                :disabled="$can('edit', 'contact.tab_notes') && item.disabled"
                name="event-filter"
                :value="getRole(item.role)"
                class="mb-50 mr-2 pr-50"
                :class="`custom-control-primary`"
              >
                {{ $t('label_' + item.role) }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>

          <template #cell(actions)="data">

            <b-dropdown
              :disabled="!($can('edit', 'contact.tab_notes') && ['admin'].includes(userData.role))"
              dropleft
              boundary="window"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
              @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>


              <b-dropdown-item @click="$emit('edit-item', data.item); $bvModal.show(PREFIX + '-modal')">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="$emit('delete-item', data.item.id);deleteItem(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>
      <!--pagination-->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="tableData.currentPage"
              :total-rows="tableTotal"
              :per-page="tableData.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import {

  BTable, BDropdownItem, BDropdown, BPagination, BFormCheckboxGroup, BFormCheckbox, BButton

} from 'bootstrap-vue'

    import partialText from '@/views/components/partialText'
    import vSelect from 'vue-select'
    import { CONTACT_PREFIX as MODULE_PREFIX, NOTE_PREFIX as PREFIX} from "../moduleHelper"

    export default {
        components: {

            BTable,
BDropdownItem,
BDropdown,
BPagination,
BFormCheckboxGroup,
BFormCheckbox,
            vSelect,
partialText,
BButton,
        },
        props: ['moduleItem'],
        data() {
            return {
              userData: JSON.parse(localStorage.getItem('userData')),
                MODULE_PREFIX,
                PREFIX,
                roleItems: [],
                columnsDefs: [
                    {
label: 'label_date',
key: 'deadline_at',
sortable: true,
formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }
},
                    {label: 'label_title', key: 'title', sortable: true},
                    {label: 'label_description', key: 'description', sortable: false},
                    {label: 'label_author', key: 'user_name', sortable: true},
                    {label: 'label_shared_for', key: 'share_to_role'},
                    {label: 'label_action', key: 'actions'}
                ],
                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [10, 25, 50, 100],
                },
            }
        },
        computed: {
            dataMeta: (context) => ({
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }),
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
        },
        created() {
            let self = this;
            this.getPageRoles()
            this.refreshDataTable();
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (obj.key == 'actions' && !self.$can('edit', 'contact.tab_notes')) {
                    return false;
                }
                return true;
            });
        },
        methods: {
            async getPageRoles(){
              this.roleItems = this.getSystemRoles(this.userData?.role)
            },
            updateShareToRoles(roles, item) {
              const update_data = {
                deadline_at: formatDate(item.deadline_at, 'DD/MM/YYYY', 'unix'),
                share_to_role: roles,
                title: item.title,
                description: item.description,
              }
              this.async('put', '/contact_notes/'+item.id, update_data, function(resp){ // this.itemData.contact_id
                this.$bvModal.hide(this.PREFIX + '-modal');
              });
            },
            refreshDataTable: function () {

                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's', {

                    params: {
                        contact_id: this.moduleItem.id? this.moduleItem.id : this.$router.currentRoute.params.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items.map(item => ({ ...item, share_to_role: item.share_to_role?.split(',').filter(Boolean) ?? [] }));
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/' + id, {}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
        },
    }
</script>

<style lang="scss">
.custom-control-primary .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--theme-bg-color) !important;
  border-color: var(--theme-bg-color) !important;
  opacity: .6 !important;
}
</style>
